<template>

    <section>
        <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 d-flex mb-3">
                <div class="table-box p-3">
                    <div class="">
                        <div class="d-flex">
                            <div class="flex-grow-1">
                                <h2 class=" mb-1">Project Glossary List</h2>
                            </div>
                        </div>
                    </div>
                    <data-table id="example-data-table"
                        :options="options"
                        :data-set="data.dataList"
                        :page-size="data.pageSize"
                        @pagination="pagination"
                        @limit-change="limitChange"/>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import DataTable from "@/common/components/datatable";
    import HelperFunction from "@/common/helpers";
    import {useRoute} from "vue-router";
    import {onMounted, computed, watch} from "vue";
    import {useStore} from "vuex";

    export default {
        name: "ProjectGlossary",
        components: {
            DataTable
        },
        setup() {
            const { data, getAllData } = HelperFunction();
            const route = useRoute()
            const store = useStore()
            const company = computed(() => store.getters.getSelectedBoard)
            const projectStatus = computed(() => store.getters.getProjectStatus)
            const project_id = route.params.id
            let setParams = {
                project_id: project_id,
                company_id: ''
            };

            // SET DATATABLE PROPS
            let options = {
                columns: [
                    {
                        title: 'Title',
                        type: 'custom',
                        key: 'glossary',
                        modifier: (obj, row) => {
                            return obj.title;
                        },
                    },
                    {
                        title: 'Description',
                        type: 'custom',
                        key: 'glossary',
                        truncate: true,
                        modifier: (obj, row) => {
                            return obj.description;
                        }
                    },
                ],
                showAction: true,
                action: [
                    {
                        title: 'Edit',
                        icon: 'edit',
                        type: 'edit',
                        show: false
                    },
                    {
                        title: 'Delete',
                        icon: 'trash-alt',
                        type: 'delete',
                        show: false
                    }
                ]
            }

            data.setURL = vueConfig.Project.Overview.GlossaryEndPoint

            watch(() => projectStatus.value, (value) => {
                if(value && value == 'Completed')
                {
                    options.showAction = false
                }
            }, {immediate: true});

            onMounted( () => {
                if(company.value){
                    setParams.company_id = company.value.id
                }                

                getAllData(data.setURL, setParams)
            })
            
            const pagination = (page) => {
                setParams.page = page
                getAllData(data.setURL, setParams)
            }
            const limitChange = (limit) => {
                setParams.limit = limit
                delete setParams.page
                getAllData(data.setURL, setParams);
            }

            return {
                data, options, pagination, limitChange, projectStatus, setParams
            }
        }
    }
</script>

<style scoped>

</style>
